
.process-list {
    > [class*="col"] {
        .process-card {
            padding: 40px;
            height: 100%;
            border-radius: 16px;
            max-width: 400px;
            margin: 0 auto;
            word-break: keep-all;
        }
        &:not(:last-child) {
            .process-card {
                position: relative;
                &::after {
                    content: "";
                    position: absolute;
                    right: 50%;
                    bottom: -28px;
                    transform: translateX(50%) rotate(90deg);
                    width: 24px;
                    height: 24px;
                    background: url(/images/guide/temporary/process-arrow.svg) no-repeat center;
                }
            }
        }
    }
}
.process-table {
    margin-top: 40px;
    border-top: 2px solid #111;
    border-radius: 0;
    tr {
        border-bottom: 1px solid #ececec;
    }
    th,
    td {
        padding: 12px 16px;
    }
    th {
        font-weight: 500;
    }
    td {
        color: #666;
    }
}
@media (min-width: 768px) {
    .process-list {
        > [class*="col"] {
            &:not(:last-child) {
                .process-card {
                    position: relative;
                    &::after {
                        content: "";
                        position: absolute;
                        right: -32px;
                        bottom: 50%;
                        transform: translatey(50%);
                        width: 24px;
                        height: 24px;
                        background: url(/images/guide/temporary/process-arrow.svg) no-repeat center;
                    }
                }
            }
        }
    }
    .process-table {
        margin-top: 82px;
        th,
        td {
            padding: 16px 20px;
        }
        th {
            width: 200px;
        }
    }
}
@media (min-width: 1024px) {
}
